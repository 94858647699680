.top_container {
    width: 100%;
    padding-bottom: 20px;
}

.first_heading_row {
    display: flex;
    justify-content: space-between;
    /* padding-top: 40px; */
}

.sub_left_heading h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 130%;
    color: #212121;
    /* margin-bottom: 20px; */
}

.sub_right_balance h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 130%;
    color: #212121;
}

.sub_right_balance h3 span {
    color: #F47A20;
    font-weight: 700;
    font-size: 25px;
    line-height: 130%;
}

.second_menu_row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.second_menu_row a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 10px;
}

.third_connect_wallet {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.third_connect_wallet .w_connect_content {
    width: 100%;
}

.third_connect_wallet div p {
    background: #FFFFFF;
    border: 1px solid #DAD3D3;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8E8D8D;
    padding: 9.5px 17px;
    margin-right: 10px;
    width: 100% !important;
}

.swap_button {
    padding-left: 10px;
}

.swap_button button {
    background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    padding: 7.5px 16.5px;
    min-width: 164px;
}

.swap_button button:hover {
    filter: brightness(0.95);
}

.last_bottom_text p {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #8E8D8D;
    margin-left: 17px;
    margin-bottom: 0;
}

.last_bottom_text p a {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: #F47A20;
    text-decoration: none;
}


.active_menu_sub {
    background: #F47A20;
}

.second_menu_row a:hover {
    color: inherit;
}

.active_menu_sub span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

}


@media screen and (max-width: 768px) {
    .third_connect_wallet {
        display: block;
        width: 100%;
    }
    .swap_button {
        padding-left: 0;

    }
    .last_bottom_text p {
        margin-left: 3px;
        margin-top: 10px;
    }
    .swap_button button {
        font-size: 13px;
        padding: 5px 12px;
        min-width: 120px;
    }
    .third_connect_wallet div p {
        font-size: 11px;
        padding: 9.5px 9.5px;
    }
    .second_menu_row {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    
    .second_menu_row a {
        font-size: 13px;
        margin-right: 5px;
    }
    .active_menu_sub span {
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: #FFFFFF;
    }
    .second_menu_row span{
        font-size: 13px;
        line-height: 24px;
    }
    .sub_left_heading h3 {
        font-weight: 600;
        font-size: 20px;
    }
    
    .sub_right_balance h3 {
        font-weight: 600;
        font-size: 20px;
    }
    
    .sub_right_balance h3 span {
        font-weight: 600;
        font-size: 20px;
    }
}