.product_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

/* .product_container div {
    width: 100%;
} */

.product_image {
    text-align: center;
    width: 50%;
    
}
.product_image img{
    width: 100%;
    max-width: 318px;
}

.product_content {
    width: 50%;
}
.product_content .theme_heading{
    justify-content: flex-start;
}
.product_list{
    margin-top: 40px;
}

.product_wrapper {
    display: flex;
    padding: 10px 20px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
}

/* .product_text{
    width: 100%;
    background-color: darkblue;
} */



.product_text h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #212121;
    margin-bottom: 10px;
}

.product_text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #494949;
    margin-bottom: 0;
}

@media screen and (max-width: 768px){
    .product_container {
        padding-top: 70px;
        flex-direction: column-reverse;
    }
    .product_image {
        text-align: center;
        width: 100%;
    }
    .product_image img{
        width: 100%;
    }
    .product_content {
        width: 100%;
    }
    .product_list{
        margin-top: 20px;
    }
    
}