.left_menu_container {
    width: 100%;
    max-width: 220px;
    position: sticky;
    top: 20px;
    left: 0px;
    /* padding-left: 85px; */
}

.mobile_footer_mint, .bottom_navigation {
    display: none;
}

.left_menu_items {
    padding: 10px 14px;
    margin-bottom: 10px;
}



.left_menu_items span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FF9547;
}

.left_menu_items img {
    margin-right: 20px;
}

.line_break {
    width: 100%;
    max-width: 219px;
    border-top: 0.5px solid #AE4C04;
}

.left_menu_icons {
    padding-top: 40px;
    padding-left: 14px;
}

.left_menu_icons span {
    font-size: 20px;
    color: #FF9547;
    margin-right: 20px;
    cursor: pointer;
}

.left_menu_icons span:hover {
    transition: all 0.1s ease-in-out;
    color: #F47A20;
}

.left_menu_contact {
    padding-left: 14px;
    padding-top: 16px;
}

.left_menu_contact span {
    font-weight: 700;
    font-size: 12px;
    line-height: 200%;
    color: #FF9547;
}

.left_menu_contact p a {
    font-weight: normal;
    font-size: 12px;
    line-height: 200%;
    color: #FF9547;
    text-decoration: none;
}

.left_language {
    margin-top: 20px;
}

.left_language img {
    cursor: pointer;
}

.active_menu,
.in_active_menu {
    text-decoration: none;
}

.active_menu div {
    background: #F47A20;
    box-shadow: 0px 4px 10px rgba(244, 122, 32, 0.1);
    border-radius: 5px;
}

.in_active_menu div span {
    color: #F47A20;
}

.active_menu div span {
    font-weight: 600;
    color: #FFFFFF;
}
@media screen and (max-width: 1140px){
    .left_menu_container {
        max-width: 160px;
    }
    .left_menu_items span {
        font-size: 14px;
        line-height: 22px;
    }
    .left_menu_items img {
        margin-right: 10px;
    }
    .left_menu_icons {
        padding-top: 10px;
    }
    
    .left_menu_icons span {
        font-size: 18px;
        margin-right: 10px;
    }
    .left_menu_contact p a {
        font-size: 10px;
    }
    
    .left_language {
        margin-top: 10px;
    }    
}
@media screen and (max-width: 785px) {
    .left_menu_container {
        position: sticky;
        top: 20px;
        left: 0px;
    }
}

@media screen and (max-width: 650px) {
    .left_menu_container {
        display: none;
    }

    .mobile_footer_mint {
        display: block;
        background: linear-gradient(1turn, #404040, #131313 113%);
        width: 100%;
        padding-bottom: 60px;
    }

    .left_menu_icons {
        padding-top: 10px;
        padding-left: 14px;
    }

    .f_theme_button {
        margin-left: 14px;
        margin-top: 10px;
        border-top: 2px solid #676767;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    /*  */

    .bottom_navigation {

        z-index: 2;
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        border-top: 1px solid #e7e3eb;
    }

    .bottom_menu_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 1.5;
        font-weight: 400;
        font-size: 10px;
    }

    .bottom_navigation a {
        width: 100%;
        margin: 0px;
    }

    .bottom_menu_item span {
        color: inherit;
        text-align: center;
    }

    .in_active_menu {
        padding: 5px 10px;
        border-radius: 5px;
    }

    .active_menu {
        background-color: #F47A20;
        padding: 5px 10px;
        border-radius: 5px;
    }


}