.liquidity_inputs_container {
    width: 100%;
    max-width: 262px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 10px;

}

.liquidity_heading {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.liquidity_heading h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin: 0;
}
.liquidity_head_icons span:nth-child(1){
    margin-right: 18px;
}
.liquidity_msg_boxr {
    background: #949494;
    border: 1px solid #DAD3D3;
    border-radius: 5px;
    padding: 15px 17px;
    margin-bottom: 5px;
}

.liquidity_msg_boxr p {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin: 0;
    color: #FFFFFF;
}
.liquidity_inputs_container .mint_theme_button{
    padding: 20px;
}