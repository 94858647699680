.portfolio_main_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
}

.portfolio_left_section {
    padding: 17px 19px;
    backdrop-filter: blur(25px);
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    max-width: 336px;
}
/* .portfolio_left_section_img,
.portfolio_left_section_img img {
    width: 100%;
} */
.portfolio_middle_section {
    margin-left: 20px;
    width: 100%;
    max-width: 195px;
    flex-shrink: 1;


}

.portfolio_middle_section_top {
    padding: 17px 19px;
    backdrop-filter: blur(25px);
    padding-bottom: 0px;
    border-radius: 10px;
    background-color: #fff;
}

.portfolio_middle_section_top .portfolio_content_card h3 {
    margin-bottom: 10px;
}

.portfolio_middle_section_top .portfolio_content_card h2 {
    padding-bottom: 10px;
}

.portfolio_middle_section_bottom {
    margin-top: 20px;
    padding: 17px 19px;
    backdrop-filter: blur(25px);
    border-radius: 10px;
    background-color: #fff;
    padding-bottom: 0px;

}

.portfolio_content_card span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #A7A5A5;
}

.portfolio_content_card h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    color: #F47A20;
    margin: 0;
    margin-bottom: 15px;

}

.portfolio_content_card h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    color: #494949;
    margin-bottom: 15px;
}

.portfolio_content_card h3.orange_color,
.portfolio_content_card h2.orange_color {
    color: #F47A20;

}

.portfolio_right_section {
    margin-left: 20px;
    width: 100%;
    max-width: 410px;
}

.portfolio_right_section_top {
    display: flex;
}

.portfolio_right_section_top_left {
    background: #FFFFFF;
    backdrop-filter: blur(25px);
    border-radius: 10px;
    margin-right: 20px;
    padding: 17px 19px;
    padding-bottom: 15px;
}

.portfolio_right_section_top_left h3 {
    margin: 0;
}

.portfolio_content_card p {
    background: #FFEDA3;
    border-radius: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #494949;
    padding: 2px 5px 2px 5px;
    margin: 0;
    margin-bottom: 10px;
}

.portfolio_right_section_top_right {
    width: 100%;
    max-width: 195px;
}

.portfolio_right_section_top_right img {
    width: 100%;
    height: 216px;
}

.portfolio_right_section_bottom {
    height: 365px;
    margin-top: 15px;
    overflow: auto;
}

.portfolio_right_section_bottom table {
    width: 100%;
    border-radius: 5px;
}

.portfolio_right_section_bottom table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;

}

.portfolio_right_section_bottom table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.portfolio_right_section_bottom table th {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    text-align: left;
    padding-left: 10px;
    color: #212121;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #F5F5F5;
    ;
}




.portfolio_bottom_text {
    background-color: white;
}

.portfolio_right_section_bottom table .border_bottom_tr td {
    padding: 5px !important;

}

.portfolio_top_heading th {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #212121;
}

.portfolio_bottom_text td span {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: #212121;
}

.portfolio_bottom_text td {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #212121;

}

.portfolio_bottom_text td p {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: #F47A20;
    margin: 0;
}

.portfolio_bottom_text td button {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    background: #F47A20;
    border-radius: 5px;
    padding: 2px 7px;
    border: none;
}
@media screen and (max-width: 1300px) {
    .portfolio_main_container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
        flex-wrap: wrap;
    }
    
    .portfolio_right_section {
        margin-left: 0px;
        width: 100%;
        max-width: 551px;
        flex-basis: 100%;

    }
    .portfolio_right_section_top div{
        width: 100%;
    }
    .portfolio_content_card p {
        width: fit-content;

    }
}

@media screen and (max-width: 785px) {
    .portfolio_main_container {
        display: block;
    }

    .portfolio_left_section {
        padding: 17px 19px;
        width: 100%;
        max-width: none;

    }

    .portfolio_left_section_img,
    .portfolio_left_section_img img {
        width: 100%;
    }

    .portfolio_middle_section {
        margin-left: 0px;
        margin-top: 20px;
        max-width: 100%;

    }

    .portfolio_right_section {
        margin-left: 0px;
        max-width: none;
    }

    .portfolio_right_section_top {
        display: block;
    }

    .portfolio_right_section_top_left {
        margin-right: 0px;
    }

    .portfolio_right_section_top_left h3 {
        margin: 0;
    }

    .portfolio_content_card p {
        width: fit-content;

    }

    .portfolio_right_section_top_right {
        width: 100%;
        max-width: none;
        margin-top: 20px;
    }

    .portfolio_right_section_top_right img {
        width: 100%;

    }

    .portfolio_right_section_bottom {
        height: 375px;
        margin-top: 15px;
        overflow: auto;
    }

    .portfolio_right_section_bottom table {
        min-width: 410px;
    }
}