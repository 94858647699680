.roadmap_container {
    padding-top: 120px;
}

.roadmap_wrapper {
    margin-top: 40px;
}

.l_roadmap_section,
.r_roadmap_section {
    border: 1px solid #F47A20;
    border-radius: 5px;
    max-width: 592.42px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 10px 15px;
}

.roadmap_line_vertical {
    width: 3px;
    height: 50px;
    background: #F47A20;
    margin: 0 auto;
}

.r_roadmap_section .roadmap_text {
    margin-right: 40px;
    text-align: right;

}

.l_roadmap_section .roadmap_text {
    margin-left: 40px;

}

.roadmap_text h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #212121;
    margin-bottom: 10px;
}

.right_heading {}

.roadmap_text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #494949;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .roadmap_container {
        padding-top: 70px;
    }
    .roadmap_wrapper {
        margin-top: 30px;
    }
    .l_roadmap_section,
    .r_roadmap_section {
        padding: 15px 15px;
    }

    .l_roadmap_section {
        flex-direction: column-reverse;
    }

    .r_roadmap_section {
        flex-direction: column;
    }
    .r_roadmap_section .roadmap_text {
        margin-right: 0px;
        text-align: center;

    }
    .l_roadmap_section .roadmap_text {
        margin-left: 0px;
        text-align: center;
    }
}