.top_warning_container {
    background-color: hsl(0, 2%, 12%);
    color: #fff;
    text-align: center;
    min-height: 50px;
    width: 100%;
    position: relative;
    padding: 0 42.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top_warning_container p {
    font-size: 10px;
    line-height: 25px;
    margin: 0;
    letter-spacing: 0.025rem;

}

.top_warning_container>span.close_wrapper {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
}