.vault_inputs_container {
    width: 100%;
    max-width: 480px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 10px;

}

.vault_input_wrapper label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 5px;
}

.vault_input_wrapper input {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    background: #F8F8F8;
    border: 1px solid #DAD3D3;
    border-radius: 5px;
    width: 100%;
    max-width: 442px;
    padding: 10px 10px;
    margin-bottom: 20px;
}

.mint_theme_button button {
    width: 100%;
    background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    padding: 7px 0px;
}
.mint_theme_button button:hover{
    filter: brightness(0.95);
}