.how_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.how_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
    border-radius: 20px;
    margin-top: 120px;
    padding: 0;

}

.how_container div {
    width: 100%;
}

.how_image {
    /* width: 50%; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
.how_image img{
    width: 100%;
    max-width: 465px;
}
.how_content{
    padding-left: 116px;
    padding-top: 40px;
    padding-bottom: 40px;
}
.how_content .theme_heading {
    justify-content: flex-start;
    padding-bottom: 20px;
}

.how_content .theme_heading h1 {
    color: #FFFFFF;
}

.how_text {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding: 9px 20px;

}

.how_text h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 0;

}

.how_text p {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-left: 20px;
    margin-bottom: 0;
}

@media screen and (max-width: 768px){
    .how_container {
        margin-top: 70px;
    }
    .how_image {
        display: none;
    }
    .how_content{
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding: 20px;
    }
    .how_text {
        padding: 9px 15px;
    }
    
    .how_text h1 {
        font-size: 35px;
        line-height: 55px;
    }
    
    .how_text p {
        font-size: 18px;
    }
}