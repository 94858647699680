.faq_container {
    max-width: 1260px;
    padding: 0px 40px;
    width: 100%;
    margin: 0 auto;
    padding-top: 120px;
}

.faq_wrapper {
    width: 100%;
    max-width: 896px;
    margin: 0 auto;
    margin-top: 0px !important;
}

.faq_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0px;
    cursor: pointer;
}

.faq_header h3 {
    margin-bottom: 0 !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    color: #212121 !important;
}

.faq_header button {
    background-color: transparent;
    color: #212121;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
    font-weight: 300;
    width: 40px;
    font-size: 32px;
    height: 40px;
    cursor: pointer;
    padding: 12px;
    line-height: 20px;
    transition: all 0.5s ease;
    border: none;
}

.b_active {
    transform: rotate(-45deg);
    color: #F47A20 !important;
    transition: all 0.5s ease;

}

.card {
    border: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid #212121 !important;
    border-radius: 0 !important;
}

.card-header {
    border: none !important;
    background-color: transparent !important;
    padding: 10px 0 !important;
}
.card-body {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    border: none !important;
    color: #494949 !important;
    margin-left: 0px;
    padding: 0 !important;
    padding-right: 55px !important;
    padding-top: 0 !important;
    margin-bottom: 35px !important;
    /* background-color: transparent !important; */
}
.faq_container .theme_heading{
    margin-bottom: 40px !important;
}
@media screen and (max-width: 768px) {
    .faq_container {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px 20px;
        padding-top: 70px;

    }

    .card-body {
        padding-right: 30px !important;
    }

    .faq_wrapper {
        margin-top: 60px;

    }

    .faq_header h3 {
        font-size: 16px !important;
        line-height: 24px !important;
    }
    .faq_container .theme_heading{
        margin-bottom: 30px !important;
    }
}