.touch_container {
    margin-top: 175px;
}

.get_icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.get_icons div {
    padding: 20px 20px;
    padding-bottom: 10px;
}


.inputs_container {
    width: 100%;
    max-width: 570px;
    margin: 0 auto;
}

.input_wrapper {
    margin-top: 20px;
}

.input_wrapper label {
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #141414;
    margin-bottom: 6px;
}

.input_wrapper span {
    color: rgb(252, 24, 24);
}

.input_wrapper input {
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    width: 100%;
    padding: 10.5px 15px;
    outline: none;
}

.input_wrapper textarea {
    /* min-height: 10px; */
    padding: 11px 15px;
    width: 100%;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    width: 100%;
    resize: none;
    outline: none;



}


.get_icons_text span {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #212121;
}

.bold_text {
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 140%;
    color: #141414;
}

.get_icons_text p {
    display: inline;
    font-size: 20px;
    font-weight: 900;
    margin: 0%;
    margin-right: 10px;
}

.touch_button {
    text-align: right;
    margin-top: 20px;
}

.touch_button button {
    background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
    border-radius: 5px;
    padding: 7px 80px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    border: none;

}
.touch_button button:hover{
    filter: brightness(0.95);
}

@media screen and (max-width: 768px) {
    .touch_container {
        margin-top: 70px;
    }
    .get_icons {
        display: block;
        text-align: center;
        margin-top: 20px;
    }
    .get_icons div {
        padding: 0px;
        padding-bottom: 5px;
    }
    .inputs_container {
        margin-top: 30px;
    }
    .touch_button {
        text-align: left;
        margin-top: 20px;
    }
}