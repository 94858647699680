.mint_navbar_container_wrapper {
    background-color: #FFFFFF;
    padding: 0px;

}

.mobile_logo {
    display: none;
}

.mint_navbar_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 50px;
    max-width: 1440px;
    margin: 0 auto;
}

.mint_navbar_container .logo_container {
    max-width: 220px;
}

.mint_navbar_container .logo_container img {
    width: 100%;
}

.nav_button1 button img {
    margin-right: 7px;

}

.nav_button1 button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 7px 17px;
    background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
    border-radius: 30px;
    border: none;
}

.nav_button2 button img {
    margin-right: 7px;

}

.nav_button1 button:hover {
    filter: brightness(0.95);
}

.nav_button2 button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #F47A20;
    padding: 7px 17px;
    border: 2px solid #F47A20;
    background-color: #FFFFFF;
    border-radius: 30px;
}

.nav_button2 button:hover {
    filter: brightness(0.95);
}

.theme_change img {
    cursor: pointer;
}

.mint_nav_right {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mint_nav_right div {
    margin-left: 20px;
}

@media screen and (max-width: 1140px) {
    .mint_navbar_container {
        padding: 10px 20px;

    }
}

@media screen and (max-width: 768px) {
    .mint_navbar_container_wrapper {
        padding: 00px;
    }

    .mint_navbar_container {
        padding: 10px;


    }

    .dekstop_logo {
        display: none;
    }

    .mobile_logo {
        display: block;
    }

    .nav_button1 button {
        font-size: 14px;
        line-height: 22px;
        padding: 6px 12px;
    }

    .nav_button1 button img {
        width: 14px;
    }

    .nav_button2 button img {
        width: 20px;
    }

    .nav_button2 button {
        font-size: 14px;
        line-height: 22px;
        color: #F47A20;
        padding: 4px 12px;
    }

    .mint_nav_right div {
        margin-left: 10px;
    }
}

@media screen and (max-width: 535px) {
    .theme_change {
        display: none;
    }
}