.why_wrapper {
    padding-top: 100px;
}

.why_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 43px;

}

.why_image_container {
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    max-width: 292px;
    padding: 20px;
}

.why_images {
    text-align: center;
    width: 100%;
}

.why_images_text h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    text-align: center;
    color: #F47A20;
    margin: 0;

}

.why_images_text h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #212121;
    text-align: center;
    /* margin: 0; */

}

.why_images_text p {

    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #494949;
}

@media screen and (max-width: 792px) {
    .why_wrapper {
        padding-top: 70px;
        max-width: 540px;
        margin: 0 auto;
    }

    .why_container {
        display: block;
        padding-top: 30px;
    }

    .why_image_container {
        max-width: none;
        padding: 20px;
        margin-bottom: 10px;
    }
}