@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  padding: 0%;
  font-family: 'Poppins', sans-serif;
  /* background: radial-gradient(50% 50% at 50% 50%, rgba(244, 122, 32, 0.3) 0%, rgba(255, 255, 255, 0) 100%) !important; */
  background-color: #F5F5F5 !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0%;
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(169, 169, 169);
  border-radius: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}


::-webkit-scrollbar:horizontal {
  height: 4px;
  background-color: red;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgb(169, 169, 169);
  border-radius: 10px;

}


.main_container {
  background-image: url("/src/assets/images/bg_01.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.theme_container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

.theme_heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme_heading h1 {
  font-size: 40px;
  line-height: 52px;
  color: #212121;
  font-weight: 700;
  margin-left: 12px;
  margin-bottom: 0px;
}


/* Mint pages General CSS */

.theme_container_mint {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 50px;
  padding-top: 50px;
}

.mint_right_section {
  width: 100%;
  padding-left: 51px;

}

@media screen and (max-width: 1140px) {
  .theme_container_mint {
    padding: 0 20px;
    padding-top: 30px;
  }

  .mint_right_section {
    width: 100%;
    padding-left: 30px;

  }
}

@media screen and (max-width: 1018px) {
  .theme_container {
    padding: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .theme_container {
    max-width: 540px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .theme_heading h1 {
    font-size: 35px;
    line-height: 45px;
  }
}

@media screen and (max-width: 650px) {
  .mint_right_section {
    padding-left: 0px;
    padding: 0 10px;
  }

  .theme_container_mint {
    padding: 0 0px;
    padding-top: 10px;
    flex-direction: column-reverse;
  }
}