.farm_main_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
}

.farm_graph_image {
    margin-right: 23px;
    width: 100%;
}
.farm_graph_image img{
    width: 100%;
    min-height: 371px;
}
.swap_sub_container {
    width: 100%;
    max-width: 262px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 22px 20px 34px 20px;
}

.swap_icons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px;
}

.swap_icons img {
    margin-right: 10px;
}

.swap_text h2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    margin: 0;
    margin-top: 10px;
}

.swap_text input {
    background: #F8F8F8;
    border: 1px solid #DAD3D3;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    padding: 10px;
    width: 100%;
}

.swap_text span {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #212121;
    margin-bottom: 20px;
}

.swap_sub_container .mint_theme_button {
    margin-top: 47px;
}

@media screen and (max-width: 900px){
    .swap_sub_container {
        max-width: 200px;
    }
    .farm_graph_image {
        margin-right: 20px;
        width: 100%;
    }
    .farm_graph_image img{
        width: 100%;
        min-height: 250px;
    }
    .swap_sub_container .mint_theme_button {
        margin-top: 20px;
    }
} 
@media screen and (max-width: 768px) {

    .farm_main_container {
        width: 100%;
        display: block;
    }

    .farm_graph_image {
        margin-right: 0px;
    }

    .farm_graph_image img {
        width: 100%;
    }

    .swap_sub_container {
        margin-top: 20px;
        padding: 22px 20px 34px 20px;
        max-width: none;
    }

    .swap_icons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 33px;
    }


    .swap_text h2 {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: #212121;
        margin: 0;
        margin-top: 10px;
    }

    .swap_text input {
        background: #F8F8F8;
        border: 1px solid #DAD3D3;
        border-radius: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #212121;
        padding: 10px;
        width: 100%;
    }

    .swap_text span {
        font-weight: 400;
        font-size: 8px;
        line-height: 12px;
        color: #212121;
        margin-bottom: 20px;
    }

    .swap_sub_container .mint_theme_button {
        margin-top: 47px;
    }
}