.service_container {
    background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
    border-radius: 20px;
    color: white;
    padding-top: 50px;
    padding-bottom: 32.5px;
    margin-top: 105px;
}

.service_container .theme_heading h1 {
    color: #FFFFFF;
}

.service_wrapper {
    display: flex;
    padding: 17.5px 50px;
    justify-content: center;
    width: 100%;

}



.service_wrapper div h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    margin-bottom: 10px;

}

.service_wrapper div {
    width: 100%;
    margin: 0 13.5px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    padding: 10px 20px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
}

.service_wrapper div p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .service_container {
        padding-top: 0px;
        padding-bottom: 0px;
        padding: 20px;
        margin-top: 70px;
    }

    .service_wrapper {
        display: block;
        padding: 0px 0px;
    }

    .service_wrapper div {
        margin: 0 0px;
        padding: 0px 10px;
    }

    .service_wrapper div h2 {
        padding-top: 10px;
    }

    .service_container .theme_heading h1 {
        margin-bottom: 10px;
    }
}