.team_wrapper{
    width: 100%;
    padding-top: 100px;
}
.team_container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.team_images{
    padding: 0px 30px;
}

@media screen and (max-width: 768px){
    .team_wrapper{
        width: 100%;
        padding-top: 70px;
    }
    .team_container{
        display: block;
        text-align: center;
    }
    .team_images{
        padding: 20px 0px;
    }
}