.market_container {
    width: 100%;
    max-width: 587px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 10px;

}

.market_heading_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 26px 33px;
}

.m_heading_wrapper span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #A7A5A5;
}

.m_heading_wrapper h1 {
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    color: #212121;
}

.market_list_wrapper {
    height: 230px;
    overflow: auto;
    padding: 0 20px;

}

.market_table {
    width: 100%;
    height: auto;
}

.market_table tr.market_table_row td {
    text-align: center;
    padding: 8px 10px;
}




.market_table th {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    text-align: center;
    color: #212121;
    position: -webkit-sticky;
    background-color: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-bottom: 10px;
}


.market_table_row {
    border-radius: 5px;
    border: 1px solid #DAD3D3;
}

.market_table .border_bottom_tr td {
    padding: 5px;
}



.payout_item span {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #212121;
    margin: 0px 5px;
}

.token_third_item span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #212121;
}

.discount_item_green span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #494949;
    background: #45FF6E;
    border-radius: 5px;
    padding: 2.5px;
}

.discount_item_red span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    background: #F41A1A;
    border-radius: 5px;
    padding: 2.5px;
}

.capacity_item p {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #212121;
    margin: 0;

}

.capacity_item h2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: #212121;
    margin: 0;
}

.bond_button button {
    background: #F47A20;
    border-radius: 5px;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    padding: 2px 7px;
    border: none;
}

@media screen and (max-width: 768px) {
    .market_heading_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 26px 10px;
    }

    .market_list_wrapper {
        height: 230px;
        overflow: auto;
        padding: 0 10px;
    
    }


}
@media screen and (max-width: 640px) {
    .market_table{
        min-width: 525px;
    }
}