.footer_container {
    background: linear-gradient(360deg, #404040 0%, #131313 113%);
    color: #fff;
    padding: 30px 0;
    margin-top: 143px;
}

.first_section_footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 30px;
}

.footer_left,
.f_left_container,
.footer_menu {
    width: 33.3%;

}

.footer_left {
    display: flex;
    justify-content: flex-end;
}

.f_left_container {
    padding-right: 20px;
}

.f_left_container p {
    max-width: 374px;

}

.line_bottom {
    border-bottom: 2px solid #676767;
}

.footer_left {
    color: #F3CF36;
}

.icons_container {}

.footer_container p {
    margin-top: 13px;
    margin-bottom: 0;
}

.footer_container p a {
    color: inherit;
    text-decoration: none;
    font-size: 15px;
    line-height: 200%;

}

.icons_container span {
    font-size: 24px;
    margin-right: 20px;
    cursor: pointer;
}

.icons_container span:hover {
    transition: all 0.1s ease-in-out;
    color: #F47A20;
}

.footer_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.footer_bottom p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #FFFFFF;

}

.footer_menu div {
    width: 100%;
}

.footer_menu h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #F3CF36;
    margin-bottom: 15px;
}

.footer_menu span {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.footer_menu_wrappper {
    display: flex;
}

.footer_sub_menu div {
    margin-top: 10px;
    cursor: pointer;
}

.footer_sub_menu div a {
    color: inherit;
    text-decoration: none;
}

.footer_sub_menu div a:hover {
    transition: all 0.1s ease-in-out;
    color: #F47A20;
}

.theme_change {
    cursor: pointer;
}

@media screen and (max-width: 878px) {
    .footer_container {
        padding: 20px 0;
        padding-top: 40px;
        margin-top: 70px;
    }

    .first_section_footer {
        display: block;
        width: 100%;
        padding-bottom: 30px;
    }

    .footer_left,
    .f_left_container,
    .footer_menu {
        width: 100%;
    }

    .footer_menu {
        padding-top: 30px;
    }

    .footer_container p a {
        color: inherit;
        text-decoration: none;
        font-size: 14x;
        line-height: 200%;
    
    }
    
    .icons_container span {
        font-size: 23px;
        margin-right: 20px;
        cursor: pointer;
    }
    

    .footer_menu h3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #F3CF36;
        margin-bottom: 10px;
    }
    .footer_menu span {
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        color: #FFFFFF;
    }

    .footer_left {
        display: flex;
        justify-content: flex-start;
        padding-top: 30px;
    }

    .footer_left p {
        margin: 0px;
        margin-top: 5px;
    }
    .f_left_container {
        padding-right: 0px;
    }

    .f_left_container p {
        max-width: none;

    }

    .footer_bottom {
        align-items: flex-start;
    }
}