.farm_container {
    width: 100%;
    margin-bottom: 10px;

    /* max-width: 981px; */
}

.farm_heading h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #212121;
    margin-bottom: 15px;
}

.farm_sub_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #F47A20;
    border-radius: 10px 10px 0px 0px;
    padding: 5px 10px;
}

.farm_deposit span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #A7A5A5;
}

.farm_deposit p {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #212121;
    margin: 0;
    margin-top: 10px;
}

.farm_deposit p.orange {
    color: #F47A20;
}

.farm_deposit p img {
    margin-right: 5px;
}

.farm_table_main_container {
    display: flex;
    background-color: #FFFFFF;
    padding: 0 15px;
}


.farm_table {
    width: 100%;
    height: auto;
    /* background: #F8F8F8; */
}

.farm_list_wrapper {
    width: 100%;
    height: 275px;
    overflow: auto;
    margin-left: 24px;
}

.farm_table tr td {
    text-align: center;
    padding: 8px 10px;
    background: #F8F8F8;

}

.farm_table tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.farm_table tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.farm_table th {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    text-align: center;
    color: #212121;
    position: -webkit-sticky;
    background-color: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.farm_table th:first-child {
    text-align: left;
}


.farm_table_row {
    border-radius: 10px !important;
}

.farm_table_row p {
    margin: 0;
}

.farm_table .border_bottom_tr td {
    padding: 1.5px;
    background-color: white;
}

.filter_container h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 5px;
}

.filter_input input {
    background: #F8F8F8;
    border: 1px solid #DAD3D3;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #DAD3D3;
    padding: 11px 9px;
    margin-bottom: 40px;
}

.filter_sub_text p:nth-child(1) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin: 0;
    margin-bottom: 17px;
}

.filter_sub_text p:nth-child(2) {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #F47A20;
    margin: 0;
    margin-bottom: 8px;
}

.filter_sub_text p:nth-child(3) {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #212121;
    margin: 0;
}

.farm_table tr th {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #212121;
}

.token_chain_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.token_chain_text span:nth-child(1) {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    background: #F3CF36;
    border-radius: 5px;
    padding: 2.5px;
}

.token_chain_text h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin: 0;
}

.token_chain_subtext {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.token_chain_subtext p {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #212121;
}

.token_chain_subtext span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #F47A20;
}

.bnb_item {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
}

.daily_item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #212121;
}

@media screen and (max-width: 955px) {


    .farm_table_main_container {
        display: block;
        background-color: #FFFFFF;
        padding: 0 15px;
        border-radius: 10px;
    }

    .filter_container {

        display: flex;
        justify-content: flex-start;
        align-self: center;
        padding-top: 20px;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .filter_sub_text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 20px;
        /* align-self: ; */
    }

    .filter_input input {
        margin-bottom: 0;
        margin-left: 10px;
    }

    .filter_sub_text p {
        margin: 0 !important;
    }

    .filter_sub_text p:nth-child(2) {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #F47A20;
        margin: 0;
        margin-left: 20px !important;
    }

    .filter_sub_text p:nth-child(3) {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #212121;
        margin-left: 10px !important;
    }

    .farm_list_wrapper {
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {

    .token_chain_subtext span {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #F47A20;
    }

    .token_chain_text h3 {
        font-size: 14px;
    }

    .bnb_item {
        font-size: 14px;
    }

    .daily_item {
        font-size: 14px;
    }
}


@media screen and (max-width: 640px) {

    .farm_sub_container {
        display: block;
        border: 1px solid #F47A20;
        border-radius: 10px 10px;
        padding: 10px 15px;
        padding-top: 0;
        margin-bottom: 20px;
    }

    .filter_container {

        display: block;
        justify-content: flex-start;
        align-self: center;
        padding-top: 15px;
        margin-bottom: 20px;
        margin-top: 10px;
    }


    .filter_sub_text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 0px;
    }


    .filter_input input {
        margin-bottom: 30px;
        margin-left: 0px;
        margin-top: 5px;
    }

    .farm_deposit {
        margin-top: 10px;
    }



    .farm_table_main_container {
        display: block;
        background-color: #FFFFFF;
        padding: 0 15px;
        border-radius: 10px;
    }

    .filter_container {
        padding-top: 15px;
    }

    .filter_sub_text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .filter_sub_text p {
        margin: 0 !important;
    }

    .filter_sub_text p:nth-child(2) {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #F47A20;
        margin: 0;
        margin-left: 20px !important;
    }

    .filter_sub_text p:nth-child(3) {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #212121;
        margin-left: 10px !important;
    }

    .filter_input input {
        margin-bottom: 30px;
    }

    .farm_list_wrapper {
        width: 100%;
        height: 275px;
        overflow: auto;
        margin-left: 0px;
        margin-top: 20px;
    }

    .farm_table {
        width: 100%;
        min-width: 500px;
    }
}