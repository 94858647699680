.optimize_container {
    background: #FFFFFF;
    border-radius: 10px;
    width: 100%;
    max-width: 371px;
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 10px;

}

.optimize_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.arr_top{
    cursor: pointer;
}
.optimize_head div{
    margin-bottom: 30px;
}
.optimize_head div span{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin-left: 10px;
}

.liquidity_discount p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #A7A5A5;
    margin-bottom: 0;
}

.liquidity_discount h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 0;
    color: #F47A20;
}

.liquidity_content {
    background: #F3F3F3;    
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
}
.liquidity_content div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}