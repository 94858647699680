.vault_dashboard_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
}

.vault_dashboard_container div {
    width: 100%;
}

.vault_dashboard_left {
    backdrop-filter: blur(25px);
    border-radius: 10px;
    width: 100%;
    padding-right: 10px;
    padding-bottom: 16px;
    /* max-width: 480px; */
}

.vault_dashboard_content {
    display: flex;
    flex-wrap: wrap;
}

.vd_card {
    background: #FFFFFF;
    backdrop-filter: blur(25px);
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 20px;
    /* min-height: 68px; */
}

.big_ {
    padding: 17px 20px;
}

.vault_dashboard_content div.vd_card {
    flex-basis: calc(50% - 10px);

}

.vault_dashboard_content div.vd_card:nth-child(even) {
    margin-left: 10px;
}

.vault_dashboard_content div.vd_card:nth-child(odd) {
    margin-right: 10px;
}



.vd_card p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #A7A5A5;
    margin: 0;
}

.vd_card h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #494949;
    margin: 0;

}

.total_deposit p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    margin: 0;
}

.total_deposit p span {
    color: #FF9547;
}

.vault_dashboard_right {
    margin-left: 10px;
}

.animated_line_wrapper {
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 10px;
}

.animated_line {
    background: linear-gradient(278.37deg, #F47A20 -14.9%, #F3CE36 72.95%);
    border-radius: 10px;
    height: 30px;

}


.deposit_container {
    width: 100%;
    background: #FFFFFF;
    backdrop-filter: blur(25px);
    border-radius: 10px;
    padding: 0px 20px;
    padding-top: 19px;
    padding-bottom: 16px;
    margin-top: 20px;
}

.deposit_container h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    padding-bottom: 20px;
}

.deposit_input_label {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.deposit_input_label span {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #212121;
}

.deposit_input_wrapper label {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
}

.deposit_input_value {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 15px;
}

.deposit_input_wrapper input {
    width: 100%;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    background: #F8F8F8;
    border: 1px solid #DAD3D3;
    border-radius: 5px;
    padding: 10px 9px;
}

.deposit_guidline {
    margin-bottom: 23px;
}

.deposit_guidline p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 0;
    padding-top: 5px;
    padding-left: 10px;
}

@media screen and (max-width: 820px) {
    .vd_card p {
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
        color: #A7A5A5;
        margin: 0;
    }

    .vd_card h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #494949;
        margin: 0;
    }

    .deposit_guidline p {
        font-size: 11px;
    }

}

@media screen and (max-width: 772px) {
    .vault_dashboard_container {
        display: block;
        margin-bottom: 10px;
    }

    .vault_dashboard_right {
        margin-left: 0px;
        margin-top: 10px;
    }

}