.hero_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.hero_image {
    width: 45%;
    text-align: center;
}

.hero_image img {
    width: 100%;
    max-width: 416px;
}

.hero_content {
    width: 55%;
    max-width: 596px;
}

.hero_content h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    color: #212121;
}

.hero_content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #494949;
    margin-top: 20px;
    margin-bottom: 20px;
}

.hero_content h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 20px;
}

.hero_sub_images {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.hero_sub_images img:hover{
filter: brightness(0.85);
}

.hero_sub_images img {
    cursor: pointer;
}

.hero_sub_images div {
    margin-right: 20px;
}

@media screen and (max-width: 1081px) {

    .hero_image {
        width: 100%;
        text-align: left;
        margin-top: 15px;
    }
    .hero_content h1 {
        font-size: 40px;

    }

    .hero_image img {
        width: 90%;
    }

    .hero_sub_images div{
        width: 100%;
    }
    .hero_sub_images div img {
        width: 100%;
    }

    .hero_image {
        width: 45%;
    }
   
}

@media screen and (max-width: 768px) {
    .hero_container {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 25px;
    }

    .hero_image {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }

    .hero_image img {
        width: 100%;
    }

    .hero_content {
        width: 100%;
        max-width: none;
    }

    .hero_content h1 {
        font-size: 40px;
        line-height: 100%;
    }

    .hero_sub_images div {
        margin-right: 10px;
    }
}

@media screen and (max-width: 485px) {
    .hero_content h1 {
        font-size: 35px;
    }

    .hero_content p {
        font-size: 13px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .hero_content h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .download_images {
        max-width: 120px;
    }

    .download_images img {
        width: 100%;
    }
}