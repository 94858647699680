.community_wrapper {
    padding-top: 100px;
}

.community_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.community_heading h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #212121;
    margin-bottom: 20px;
}

.sub_community {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 11px;
    padding: 26px 23px;
}

.sub_community:nth-child(1) {
    margin-left: 0px;
}

.sub_community:last-child {
    margin-right: 0px;
}

.sub_community h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    margin: 0;
}

.sub_community p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #494949;
    margin: 0;

}

.ommunity_sub_text {
    margin-left: 20px;
}
.a_link {
    text-decoration: none;
}

@media screen and (max-width: 994px) {

    .community_container {
        display: flex;
        align-items: center;
        justify-content:center;
        flex-wrap: wrap;
    }

    .sub_community {
    
        width: 100%;
        margin: 10px;
        padding: 26px 23px;
        max-width: 45%;
    }

    .sub_community:nth-child(1) {
        margin-left: 10px;
    }

    .sub_community:last-child {
        margin-right: 10px;
    }

}

@media screen and (max-width: 768px) {
    .community_wrapper {
        padding-top: 70px;
    }

    .community_container {
        display: block;
    }

    .sub_community {
        margin: 0 0px;
        padding: 20px 23px;
        margin-bottom: 10px;
        width: 100%;
        max-width: none;
    }
    .sub_community:nth-child(1) {
        margin-left: 0px;
    }

    .sub_community:last-child {
        margin-right: 0px;
    }

    .sub_community h3 {
        font-size: 15px;
    }

    .sub_community p {
        font-size: 15px;
    }

    .ommunity_sub_text {
        margin-left: 20px;
    }
}