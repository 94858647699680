.about_main_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
}

.about_container {
    width: 55%;
    max-width: 596px;
    margin: 0 auto;
}

.about_image {
    width: 45%;
    text-align: center;
}
.about_image img{
    width: 100%;
    max-width: 467px;
}
.about_p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #494949;
    margin-left: 36px;
}
.about_button{
    margin-left: 36px;
    margin-top: 20px;
}
.about_button button.button_1 {
    background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 7px 47px;
    border: none;
    margin-right: 20px;
}
.about_button button.button_2 {
    border: 1px solid #F47A20;
    border-radius: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #F47A20;
    padding: 7px 33px;
}
.about_button button:hover{
    filter: brightness(0.95);
}
.about_container .theme_heading{
    justify-content: flex-start;
    margin-bottom: 20px;
}

@media screen and (max-width: 768px){
    .about_main_container {
        display: block;
        padding-top: 70px;
    }
    
    .about_container {
        width: 100%;
        max-width: 596px;
    }
    
    .about_image {
        width: 100%;
        padding-top: 30px;
    }
    .about_p {
        margin-left: 0px;
    }
    .about_button{
        margin-left: 0px;
    }
    .about_button button.button_1 {
        margin-right: 10px;
    }
    .about_container .theme_heading{
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 385px){
    .about_button button.button_1 {
        font-size: 14px;
        line-height: 22px;
        padding: 7px 25px;
        margin-right: 10px;
    }
    .about_button button.button_2 {
        font-size: 14px;
        line-height: 22px;
        padding: 7px 13px;
    }
}