.mobile-menu {
    display: none;
}

.mobile_toggle_button {
    display: none;
}



.navbar_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid #CECCCC;
    padding-top: 23px;
    padding-bottom: 10px;
    width: 100%;
    /* position: sticky;
    top: 0;
    background-color: #FFFFFF; */

}

.menu_container {
    margin-left: 70px;

}

.menu_container span {
    margin-left: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.menu_container span a {
    color: inherit;
    text-decoration: none;
}

.menu_container span a:hover {
    transition: all 0.1s ease-in-out;
    color: #F47A20;
}


.nav_right_container {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.lang_container {
    cursor: pointer;
}

.theme_btn_container {
    margin-left: 20px;
    cursor: pointer;
}

.theme_button {
    margin-left: 20px;
}

.theme_button button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 7px 16px;
    background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
    border-radius: 30px;
    border: none;
}

.theme_button button:hover {
    filter: brightness(0.95);
}

.logo_container {
    cursor: pointer;
}

@media screen and (max-width: 1220px) {
    .menu_container {
        margin-left: 30px;
    }

    .menu_container span {
        margin-left: 20px;
        font-size: 15px;
    }

    .theme_btn_container {
        margin-left: 15px;
    }

    .theme_button {
        margin-left: 15px;
    }

    .theme_button button {
        font-size: 15px;
        padding: 7px 14px;
    }
}

@media screen and (max-width: 1050px) {
    .menu_container {
        margin-left: 15px;
    }
    
    .theme_btn_container {
        display: none;
    }


    .menu_container span {
        margin-left: 13px;
        font-size: 14px;
    }

    .theme_btn_container {
        margin-left: 10px;
    }

    .theme_button {
        margin-left: 10px;
    }

    .theme_button button {
        font-size: 13px;
        padding: 6px 12px;
    }
}

@media screen and (max-width: 838px) {

    .navbar_container .menu_container {
        display: none;
    }

    .theme_btn_container {
        display: none;
    }

    .mobile_toggle_button {
        display: block;
    }

    .theme_button button {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        padding: 5px 12px;
        background: linear-gradient(278.37deg, #F3CE36 -14.9%, #F47A20 72.95%);
        border-radius: 27px;
        border: none;
    }

    .theme_btn_container_mobile img {
        height: 34px;
        width: auto;
    }

    .mobile_toggle_button {
        margin-left: 7px;
    }

    .mobile_toggle_button span {
        font-size: 32px;
        color: #F47A20;
    }



    .mobile-menu {
        display: block;
        position: fixed;
        background: #fefefe;
        height: 100vh;
        top: 0;
        right: 0;
        padding: 17px 20px;
        width: 60%;
        box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
        transition: 0.5s;
        transform: translateX(0%);
        z-index: 99999;
    }

    .mobile-menu .navbar-nav {
        padding-top: 40px;
    }

    .mobile-menu.menu-open {
        transform: translateX(100%);
    }

    .mobile-menu .nav-item .nav-link {
        color: #000;
        font-size: 16px;
        margin: 0;
        letter-spacing: 0;
        padding: 0;
        margin-bottom: 10px;
        padding-bottom: 5px;
        transition: all 0.3s ease-out;
        position: relative;
    }

    .mobile-menu .nav-item .nav-link::before {
        position: absolute;
        content: "";
        left: 0;
        width: 0;
        height: 2px;
        background: #000;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        bottom: 0;
    }

    .mobile-menu .nav-item.active .nav-link::before {
        width: 100%;
    }


    .fix-icon {
        font-size: 20px;
        color: #fff;
        position: absolute;
        right: 10px;
        top: 19px;
        cursor: pointer;
        transform: scale(1.35);
        color: #F47A20;
    }
    .top_warning_container {
        background-color: hsl(0, 2%, 12%);
        color: #fff;
        text-align: center;
        min-height: 50px;
        width: 100%;
        position: relative;
        padding: 0 30px;
        padding-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .top_warning_container p {
        font-size: 10px;
        line-height: 20px;
        margin: 0;
        letter-spacing: 0.025rem;
    }

    .top_warning_container>span.close_wrapper {
        right: 10px;
    }
}